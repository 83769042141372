import { Container } from "../Container";

function Profile({ session }: any) {
  const user = session?.user.email;
  return (
    <Container>
      <h1>My Profile</h1>
      {user ? (
        <div>
            <p>Email: {user}</p>
        </div>
      ) : (
        <div>Loading Profile...</div>
      )}
    </Container>
  );
}

export default Profile;




