import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #4caf50;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
`;

export const Input = styled.input`
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
  }
`;

export const FormButton = styled.button`
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background-color: #4caf50;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #43a047;
    transform: scale(1.05);
  }
`;

export const FormError = styled.div`
  color: red;
  font-size: 14px;
  font-weight: bold;
`;

export const FormSuccess = styled.div`
  color: green;
  font-size: 14px;
  font-weight: bold;
`;

export const Select = styled.select`
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  transition: box-shadow 0.3s ease;
`;
