export interface Activity {
  activity_name: string;
  datetime_iso8601: string;
  location: string;
  short_description: string;
}

export interface ApiResponse {
  activities: {
    [key: string]: Activity[] | Activity[];
  };
}

export function flattenActivities(responseData: ApiResponse): Activity[] {
  let activitiesArray: Activity[] = [];

  for (const key in responseData.activities) {
    if (Array.isArray(responseData.activities[key])) {
      activitiesArray = [
        ...activitiesArray,
        ...(responseData.activities[key] as Activity[]),
      ];
    }
  }

  if (!activitiesArray.length) {
    activitiesArray = responseData.activities as unknown as Activity[];
  }

  return activitiesArray;
}
