import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CancelButton = styled.button`
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #999;
  }
`;

export const ConfirmButton = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const FriendEmailInput = styled.input`
    padding: 6px 12px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    transition: box-shadow 0.3s ease;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: solid black 1px;
`;
