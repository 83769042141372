import { useEffect, useState } from "react";
import { supabase } from "../App";
import { Container } from "../Container";
import Spinner from "../Spinner";
import TripCard from "./TripCard";

function Trips({ session }: any) {
  const [trips, setTrips] = useState<any>([]);
  const [guestTrips, setGuestTrips] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const user = session?.user;

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        setLoading(true);

        const { data: tripsData, error: tripsError } = await supabase
          .from("Trips")
          .select("*")
          .eq("user_id", user?.id)
          .order("start_date", { ascending: false });
        console.log("tripsData", tripsData);
        if (tripsError) throw tripsError;

       tripsData && setTrips(tripsData);

        const { data: guestTripsData, error: guestTripsError } = await supabase
        .from("guesttrips")
        .select("*")
        .eq("guestid", user?.id);
        console.log("guestTripsData", guestTripsData);
        if (guestTripsError) throw guestTripsError;

        console.log("Guest Trips:", guestTripsData);

        if (guestTripsData) {
          const tripIds = guestTripsData.map((trip: any) => trip.tripid);
          const resolvedTripIds = await Promise.all(tripIds);

          const allGuestTrips = [];

          console.log("Trip IDs:", resolvedTripIds);

          for (const id of resolvedTripIds) {
            const { data, error } = await supabase
              .from("Trips")
              .select("*")
              .eq("id", id);
              console.log(id)

            if (error) throw error;

            if (data && data.length > 0) {
              console.log("Data for Trip ID " + id + ":", data);
              allGuestTrips.push(data[0]);
            } else {
              console.log("No data found for Trip ID " + id);
            }
          }

          console.log("All Guest Trips:", allGuestTrips);
          setGuestTrips(allGuestTrips);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrips();
  }, [user]);

  return (
    <Container>
      <h1>My Trips</h1>
      {loading ? (
        <Spinner showJokes={false}/>
      ) : (
        <>
        {guestTrips && <TripCard session={session} trips={guestTrips} isGuestTrip={true} />}
        {trips && <TripCard session={session} trips={trips} isGuestTrip={false} />}
        </>
      )}
    </Container>
  );
}

export default Trips;
