import React, { useEffect } from 'react';
import ActivityCard from './ActivityCard';
import styled from 'styled-components';

interface ItineraryProps {
    activities: Activity[];
}


interface Activity {
    activity_name: string;
    datetime_iso8601: string;
    location: string;
    short_description: string;
}

const ItineraryWrapper = styled.div`
    background-color: rgb(76, 175, 80);
    /* border-radius: 5px; */
    padding: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Itinerary: React.FC<ItineraryProps> = ({ activities }) => {
    useEffect(() => {
        console.log(activities);
    }, [activities]);
  return (
    <ItineraryWrapper>
      {activities && activities.map((activity: any, index: number) => (
        <ActivityCard key={index} activity={activity} />
      ))}
    </ItineraryWrapper>
  );
};

export default Itinerary;
