import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body, h1, h2, h3, h4, h5, h6, p, div {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
  }

  p, div {
    font-weight: 400;
  }
`;


export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
  padding: 1rem;
`;

export const AuthWrapper = styled.div`
  margin: 0 auto;
  width: 80%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative; /* Add position relative */
`;

export const SignOutButton = styled.button`
  margin-top: 0.5rem;
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #fff;
  background-color: #4caf50;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #43a047;
    transform: scale(1.05);
  }
`;

export const PageNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
`;

export const Logo = styled.img`
  width: 150px;
  height: auto;
  display: block;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
`;

export const LogoContainerAuth = styled.div`
  margin-bottom: 1rem;
  img {
    border-radius: 12px;
  }
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
