import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormLabel = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const FormInput = styled.input`
  font-size: 16px;
  padding: 6px 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const SaveButton = styled.button`
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const CancelButton = styled.button`
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #999;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Activity = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
  margin-right: 8px;
  height: 20px;
`;

export const ActivityText = styled.span`
  text-align: left;
`;

export const LocationTitle = styled.span`
  margin-bottom: 8px;
  font-weight: bold;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  margin-top: 1rem;
`;

export const SpinningContainer = styled.div`
  padding: 100px;
  padding-bottom: 100px;
  border-radius: 8px;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
