import React from "react";
import styled from "styled-components";

type Activity = {
  activity_name: string;
  datetime_iso8601: string;
  location: string;
  short_description: string;
  estimated_cost: string;
};

const ActivityWrapper = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface ActivityCardProps {
  activity: Activity;
}

const ActivityCard: React.FC<ActivityCardProps> = ({ activity }) => {
  const { activity_name, datetime_iso8601, location, short_description, estimated_cost } =
    activity;

  return (
    <ActivityWrapper>
      <h3>{activity_name}</h3>
      {(estimated_cost > `0` && estimated_cost !== `0 USD`) && <p>Estimated Cost: {estimated_cost}</p>}
      <p>{new Date(datetime_iso8601).toLocaleString()}</p>
      <p>{location}</p>
      <p>{short_description}</p>
    </ActivityWrapper>
  );
};
export default ActivityCard;
