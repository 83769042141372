import styled from "styled-components";

export const TripContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  width: 75%;
  position: relative;
`;

export const TripName = styled.h3`
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
  max-width: 30%;
`;

export const TripDetail = styled.p`
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

export const ShowDetailsButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 150px;
  &:hover {
    background-color: #43a047;
    transform: scale(1.05);
  }
`;

export const IconContainer = styled.div`
  display: flex;
  position: absolute;
  top: 1rem;
  right: 1rem;

  div {
    margin-left: 1rem;
  }

  & > svg {
    margin-left: 1rem;
    cursor: pointer;
  }
`;

export const TrashButton = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
transition: transform 0.3s ease;
&:hover {
    transform: scale(1.1);
}
`;

export const MessageButton = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
transition: transform 0.3s ease;
&:hover {
    transform: scale(1.1);
}
`;

export const EditButton = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
transition: transform 0.3s ease;
&:hover {
    transform: scale(1.1);
}
`;

export const AddFriendButton = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
transition: transform 0.3s ease;
&:hover {
    transform: scale(1.1);
}
`;