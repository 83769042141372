import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Itinerary from "../Itinerary";
import Spinner from "../Spinner";
import * as S from "./FormStyles";
import { supabase } from "../App"
import { ApiResponse, Activity, flattenActivities } from "../../utilities/flattenResponse";

function SearchForm({ session }: any) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [trip, setTrip] = useState<Activity[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [budgetCategory, setBudgetCategory] = useState<string | null>(null);

  async function addTrip(
    userId: any,
    location: any,
    startDate: any,
    endDate: any,
    budgetCategory: any,
  ) {
    try {
      const queryParams = new URLSearchParams({
        location: location,
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        budget_category: budgetCategory,
      });
      setIsLoading(true);
      const response = await fetch(
        `https://smart-travel-backend.vercel.app/gpt?${queryParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.ok) {
        const data: ApiResponse = await response.json();
        const activitiesArray = flattenActivities(data);
        setTrip(activitiesArray);
        console.log(data);
        setIsLoading(false);
        await supabase.from("Trips").insert([
          {
            location: location,
            start_date: startDate,
            end_date: endDate,
            user_id: userId,
            trip_details: data,
          },
        ]);
      } else {
        setIsError(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsError(true);
      setIsLoading(false);
    }
  }
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!budgetCategory) {
      setError("Please select a budget category.");
      return;
    }
    if (!location) {
      setError("Please enter a location.");
      return;
    }
    if (!startDate) {
      setError("Please enter a start date.");
      return;
    }
    if (!endDate) {
      setError("Please enter an end date.");
      return;
    }
    const user = session?.user;
    if (user) {
      const currentUser = user;
      setError("");
      setLocation(null);
      setStartDate(null);
      setEndDate(null);
      console.log(currentUser.id, location, startDate, endDate, budgetCategory)
      await addTrip(currentUser.id, location, startDate, endDate, budgetCategory);
    } else {
      console.error("No user found.");
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <h3>building itinerary...</h3>
          <Spinner showJokes={true} />
        </>
      ) : (
        <S.FormContainer>
          <S.FormContent>
            <S.Input
              type="text"
              name="location"
              id="location"
              placeholder="Location"
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            />
             <div>Budget:</div>
            <S.Select
              name="budget"
              id="budget"
              onChange={(e) => {
                setBudgetCategory(e.target.value);
              }}
            >
              <option value="">--Select a budget category--</option>
              <option value="100 USD per day">Budget: $50 - $100 per day</option>
              <option value="200 USD per day">Moderate: $100 - $200 per day</option>
              <option value="400 USD per day">Luxury: $200 - $500 per day</option>
              <option value="800 USD per day">Ultra Luxury: More than $500 per day</option>
            </S.Select>
            <div>Departure:</div>
            <DatePicker
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
            />
            <div>Return:</div>
            <DatePicker
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
            />
          </S.FormContent>
          <S.FormButton onClick={handleSubmit}>Add Trip</S.FormButton>
          {error && <S.FormError>{error}</S.FormError>}
          {isError && (
            <S.FormError>There was an error adding your trip.</S.FormError>
          )}
          {trip && <Itinerary activities={trip} />}
        </S.FormContainer>
      )}
    </>
  );
}

export default SearchForm;
