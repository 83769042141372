import styled from "styled-components";

export const Message = styled.div`
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 10px;
`;

export const MessageList = styled.div`
  overflow-y: auto;
  max-height: 350px;

  @media (min-width: 768px) {
    max-height: 500px;
  }
`;

export const MessageAuthor = styled.span`
  font-weight: bold;
  color: #333;
`;

export const MessageText = styled.span`
  color: #777;
  margin-left: 0.5rem;
`;

export const SendButton = styled.button`
 background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;


export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const ModalContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  max-height: 400px;

  @media (min-width: 768px) {
    width: 50%;
    max-height: 500px;
  }
`;

export const ModalMessage = styled.p`
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
`;

export const MessageInput = styled.textarea`
    width: 80%;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 20px 0;
    resize: none;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    height: 3rem;
`;

export const ExitButton = styled.button`
    position: relative;
    left: 50%;
    margin-bottom: 20px;
    transform: translateX(-50%);
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: lighter;
    border: none;
    cursor: pointer;
    color: #777;
    &:hover {
        color: #333;
    }
`;

export const CharacterCount = styled.div`
    color: #777;
    font-size: 1rem;
    margin-bottom: 20px;
    padding-left: 90%;
`;
