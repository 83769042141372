import Form from './Form';
import { Container } from '../Container';

function Home({ session }: any) {
  return (
    <Container>
      <h1>Smart Travel Itinerary</h1>
      <Form session={session} />
    </Container>
  );
}

export default Home;
