import { FC, useState, useEffect, useRef } from "react";
import * as S from "./MessageModalStyles";
import { supabase } from "../App";
import stringToColor from "../../utilities/stringToColor";

interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  tripId: string;
  user: any;
}

const MessageModal: FC<MessageModalProps> = ({
  isOpen,
  onClose,
  tripId,
  user,
}) => {
  const [inputMessage, setInputMessage] = useState<string>("");
  const [messages, setMessages] = useState<any[]>([]);
  const [characterCount, setCharacterCount] = useState<number>(0);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tripId) {
      fetchMessages(tripId);
    }
  }, [isOpen, tripId]);

  useEffect(() => {
    setCharacterCount(inputMessage.length);
  }, [inputMessage]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const myChannel = supabase
      .channel("any")
      .on("postgres_changes", { event: "*", schema: "*" }, (payload) => {
        console.log("Change received!", payload);
        fetchMessages(tripId);
      })
      .subscribe();

    return () => {
      supabase.removeChannel(myChannel);
    };
  }, [tripId]);

  const fetchMessages = async (trip_id: string) => {
    const { data, error } = await supabase
      .from("messages")
      .select("*")
      .eq("trip_id", trip_id)
      .order("timestamp", { ascending: true });

    if (error) {
      console.error("Error fetching messages:", error);
    } else {
      setMessages(data || []);
    }
  };

  const handleSendMessage = async () => {
    if (inputMessage.length === 0) {
      return;
    }
    console.log(inputMessage)
    if (user) {
      const { error } = await supabase.from("messages").insert([
        {
          profile_id: user.user.id,
          trip_id: tripId,
          email: user.user.email,
          message: inputMessage,
        },
      ]);

      if (error) {
        console.error("Error sending message:", error);
      } else {
        setInputMessage("");
      }
    }
  };

  if (!isOpen) return null;

  return (
    <S.ModalOverlay>
      <S.ModalContainer>
        <S.ExitButton onClick={onClose}>X</S.ExitButton>
        <S.MessageList ref={messagesEndRef}>
          {messages.map((message, index) => (
            <S.Message
              key={index}
              ref={index === messages.length - 1 ? messagesEndRef : null}
            >
              <S.MessageAuthor style={{ color: stringToColor(message.email) }}>
                {message.email}:
              </S.MessageAuthor>
              <S.MessageText>{message.message}</S.MessageText>
            </S.Message>
          ))}
        </S.MessageList>
        <S.MessageInput
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Type your message here..."
          maxLength={264}
        />
        <S.CharacterCount>{characterCount}/264</S.CharacterCount>
        <S.SendButton onClick={handleSendMessage}>Send</S.SendButton>
      </S.ModalContainer>
    </S.ModalOverlay>
  );
};

export default MessageModal;
