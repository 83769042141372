import EditTrip from "./EditTrip";
import {
  ModalOverlay,
  ModalContainer,
} from "./EditTripStyles";

function EditTripModal({ trip, onSave, onCancel, isOpen }: any) {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalOverlay>
      <ModalContainer>
        <EditTrip trip={trip} onSave={onSave} onCancel={onCancel} />
      </ModalContainer>
    </ModalOverlay>
  );
}

export default EditTripModal;
