import { useState } from "react";
import { flattenActivities } from "../../utilities/flattenResponse";
import formatDate from "../../utilities/formatDate";
import EditIcon from "../Icons/EditIcon";
import Itinerary from "../Itinerary";
import Trash from "../Icons/TrashIcon";
import * as S from "./TripsStyles";
import { supabase } from "../App";
import DeleteConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import EditTripModal from "../EditTrip/EditTripModal";
import AddFriendIcon from "../Icons/AddFriendIcon";
import AddFriendModal from "../AddFriendModal/AddFriendModal";
import MessageIcon from "../Icons/MessageIcon";
import MessageModal from "../MessageModal/MessageModal";

interface TripCardProps {
  trips: any;
  isGuestTrip: boolean;
  session: any;
}

function TripCard({ trips, isGuestTrip, session }: TripCardProps) {
  const [expandDetails, setExpandDetails] = useState<Record<string, boolean>>(
    {}
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tripIdSelected, setTripIdSelected] = useState<string | null>(null);
  const [tripList, setTripList] = useState(trips);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [tripToEdit, setTripToEdit] = useState<any>(null);
  const [tripToAddFriend, setTripToAddFriend] = useState<any>(null);
  const [isAddFriendModalOpen, setIsAddFriendModalOpen] = useState(false);
  const [isGuestTripToDelete, setIsGuestTripToDelete] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const addFriendToTrip = async (email: string, tripId: string) => {
    try {
      const { data: profiles, error } = await supabase
        .from("Profiles")
        .select("*")
        .eq("email", email);

      if (error) {
        console.error("Error:", error);
      } else if (!profiles || profiles.length === 0) {
        alert(`No user found with email: ${email}`);
      } else {
        const newGuestTrip = {
          guestid: profiles[0].id,
          tripid: tripId,
          invitestatus: "Accepted", // or whatever default status you want to set
        };

        // Insert the new guest-trip record into the GuestTrips table
        const { data: newGuestTripData, error: insertError } = await supabase
          .from("guesttrips")
          .insert([newGuestTrip]);
        console.log(newGuestTripData);
        if (insertError) {
          console.error("Error:", insertError);
        } else {
          alert(`Added: ${profiles[0].email} to trip`);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleOpenMessageModal = (tripId: string) => {
    setIsMessageModalOpen(true);
    setTripIdSelected(tripId);
  };

  const handleCloseMessageModal = () => {
    setIsMessageModalOpen(false);
  };

  const toggleExpandDetails = (tripId: string) => {
    setExpandDetails((prevState) => ({
      ...prevState,
      [tripId]: !prevState[tripId],
    }));
  };

  const handleEdit = (trip: any) => {
    setTripToEdit(trip);
    setIsEditModalOpen(true);
  };

  const handleAddFriend = (tripId: string) => {
    setTripToAddFriend(tripId);
    setIsAddFriendModalOpen(true);
  };

  const handleAddFriendCancel = () => {
    setIsAddFriendModalOpen(false);
  };

  const handleAddFriendConfirm = (email: string, tripId: string) => {
    addFriendToTrip(email, tripId);
    setIsAddFriendModalOpen(false);
  };

  const handleEditSave = async (updatedTrip: any) => {
    setIsEditModalOpen(false);
  };

  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };
  const handleDelete = (tripId: string, isGuestTrip?: boolean) => {
    setTripIdSelected(tripId);
    setIsGuestTripToDelete(isGuestTrip ? true : false)
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleModalConfirm = async () => {
    if (tripIdSelected) {
      try {
        const { error } = await supabase
          .from("Trips")
          .delete()
          .eq("id", tripIdSelected);
        if (error) {
          console.log("Error deleting trip:", error);
        } else {
          console.log("Trip deleted successfully");
          setTripList(
            tripList.filter((trip: any) => trip.id !== tripIdSelected)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    setIsModalOpen(false);
  };

  const handleGuestModalConfirm = async () => {
    if (tripIdSelected) {
      try {
        const { error } = await supabase
            .from("guesttrips")
            .delete()
            .eq("tripid", tripIdSelected)
            .eq("guestid", session.user.id);
        if (error) {
          console.log("Error deleting guest trip:", error);
        } else {
          setTripList(
              tripList.filter((trip: any) => trip.id !== tripIdSelected)
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    setIsModalOpen(false);
  };
};

  return (
    <>
      <DeleteConfirmationModal
        isOpen={isModalOpen}
        onCancel={handleModalCancel}
        onConfirm={handleModalConfirm}
        onConfirmGuest={handleGuestModalConfirm}
        isGuestTrip={isGuestTripToDelete}
        message="Are you sure you want to delete this trip?"
      />

      <MessageModal
      isOpen={isMessageModalOpen}
      onClose={handleCloseMessageModal}
      tripId={tripIdSelected ? tripIdSelected : ""}
      user={session}
      />

      {isEditModalOpen && tripToEdit && (
        <EditTripModal
          isOpen={isEditModalOpen}
          trip={tripToEdit}
          onSave={handleEditSave}
          onCancel={handleEditCancel}
        />
      )}
      <AddFriendModal
        isOpen={isAddFriendModalOpen}
        onCancel={handleAddFriendCancel}
        onConfirm={handleAddFriendConfirm}
        message="Type in your friend's email address to add them to this trip."
        tripId={tripToAddFriend}
      />
      {tripList &&
        tripList.map((trip: any) => (
          <S.TripContainer key={trip.id+`-is-guest-trip-`+isGuestTrip}>
            <S.IconContainer>
            <S.MessageButton onClick={() => handleOpenMessageModal(trip.id)}>
              <MessageIcon />
            </S.MessageButton>
              {!isGuestTrip && (
                <S.EditButton onClick={() => handleEdit(trip)}>
                  <EditIcon />
                </S.EditButton>
              )}
              <S.TrashButton onClick={() => handleDelete(trip.id, isGuestTrip)}>
                <Trash />
              </S.TrashButton>
              {!isGuestTrip && (
              <S.AddFriendButton onClick={() => handleAddFriend(trip.id)}>
                <AddFriendIcon />
              </S.AddFriendButton>
              )}
            </S.IconContainer>
            <S.TripName>{trip.location}</S.TripName>
            <S.TripDetail>Depart: {formatDate(trip.start_date)}</S.TripDetail>
            <S.TripDetail>Return: {formatDate(trip.end_date)}</S.TripDetail>
            <S.ShowDetailsButton onClick={() => toggleExpandDetails(trip.id)}>
              {" "}
              {expandDetails[trip.id] ? "Hide" : "Show"} Details
            </S.ShowDetailsButton>
            {expandDetails[trip.id] && (
              <>
                <Itinerary activities={flattenActivities(trip.trip_details)} />
              </>
            )}
          </S.TripContainer>
        ))}
    </>
  );
}

export default TripCard;
