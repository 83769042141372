const stringToColor = (string: string) => {
  const colors = [
    "#E6194B", // Red
    "#3CB44B", // Green
    "#0082C8", // Blue
    "#F58231", // Orange
    "#911EB4", // Purple
    "#46F0F0", // Cyan
    "#F032E6", // Magenta
    "#f51d8d", // Pink
    "#008080", // Teal
    "#AA6E28", // Brown
  ];

  // Compute a hash of the string
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash += string.charCodeAt(i);
  }

  // Use the hash to index into the colors array
  return colors[hash % colors.length];
};

export default stringToColor;
