import { useEffect, useState } from "react";
import { flattenActivities } from "../../utilities/flattenResponse";
import * as S from "./EditTripStyles";
import { supabase } from "../App";
import updateTrip from "../../utilities/updateTrip";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";

function EditTrip({ trip, onSave, onCancel }: any) {
  const [location] = useState(trip.location);
  const [startDate, setStartDate] = useState(trip.start_date);
  const [endDate, setEndDate] = useState(trip.end_date);
  const [selectedActivities, setSelectedActivities] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    const activitiesArray = flattenActivities(trip.trip_details);
    const goodActivities = activitiesArray
      .filter((activity: any, index: number) => selectedActivities[index])
      .map((activity: any) => activity.activity_name);

    const badActivities = activitiesArray
      .filter((activity: any, index: number) => !selectedActivities[index])
      .map((activity: any) => activity.activity_name);

    const updatedTrip = {
      name: trip.location,
      startDate,
      endDate,
      goodActivities,
      badActivities,
    };

    try {
      await updateTrip(updatedTrip, trip.id, supabase);
      onSave(updatedTrip);
    } catch (error) {
      console.error("Error updating trip: ", error);
    } finally {
      setIsLoading(false);
      history('/trips');
      window.location.reload();
    }
  };

  const handleActivityChange = (index: number, checked: boolean) => {
    setSelectedActivities((prev: any) => {
      const updatedActivities = [...prev];
      updatedActivities[index] = checked;
      return updatedActivities;
    });
  };

  useEffect(() => {
    setSelectedActivities(
      flattenActivities(trip.trip_details).map(() => false)
    );
  }, [trip]);

  const activities = flattenActivities(trip.trip_details);

  return (
    <>
      {isLoading ? (
        <S.SpinningContainer>
          <Spinner showJokes={true} />
        </S.SpinningContainer>
      ) : (
        <S.FormContainer>
          <S.LocationTitle> Location: {location}</S.LocationTitle>
          <h5>
            If you would like to change the dates of your trip please select new
            dates below.
          </h5>
          <S.DateContainer>
            <S.FormLabel>
              Start Date:
              <S.FormInput
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </S.FormLabel>
            <S.FormLabel>
              End Date:
              <S.FormInput
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </S.FormLabel>
          </S.DateContainer>
          <h5>
            Please check the activities that interest you. Your selections will
            help us tailor your trip experience.
          </h5>
          {activities &&
            activities.map((activity: any, index: number) => (
              <S.Activity key={index}>
                <input
                  type="checkbox"
                  checked={selectedActivities[index]}
                  onChange={(e) =>
                    handleActivityChange(index, e.target.checked)
                  }
                />
                <span>{activity.activity_name}</span>
              </S.Activity>
            ))}
          <S.ButtonContainer>
            <S.CancelButton onClick={onCancel}>Cancel</S.CancelButton>
            <S.SaveButton onClick={handleSubmit}>Update</S.SaveButton>
          </S.ButtonContainer>{" "}
        </S.FormContainer>
      )}
    </>
  );
}

export default EditTrip;
