import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavContainer = styled.nav`
  background-color: #4CAF50;
  padding: 1rem 0;

  ul {
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 0.5rem 1rem;
  }

  a {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    padding: 6px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }

  a:hover {
    background-color: #45a049;
  }

  a:active {
    transform: scale(0.96);
  }
`;

function Nav() {
  return (
    <NavContainer>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/profile">Profile</Link>
        </li>
        <li>
          <Link to="/trips">Trips</Link>
        </li>
      </ul>
    </NavContainer>
  );
}

export default Nav;
