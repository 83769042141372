import React from "react";
import * as S from "./ConfirmationModalStyles";

interface ConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  onConfirmGuest: () => void;
  isGuestTrip?: boolean;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  onConfirmGuest,
  isGuestTrip,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <S.ModalOverlay>
      <S.ModalContainer>
        <S.ModalMessage>{message}</S.ModalMessage>
        <S.ButtonsContainer>
          <S.CancelButton onClick={onCancel}>Cancel</S.CancelButton>
          <S.ConfirmButton onClick={isGuestTrip ? onConfirmGuest : onConfirm}>Delete</S.ConfirmButton>
        </S.ButtonsContainer>
      </S.ModalContainer>
    </S.ModalOverlay>
  );
};

export default ConfirmationModal;
