import { FC, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { supabase } from "./App";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
`;

const SpinningRingWrapper = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
`;

const Ring = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: green transparent transparent transparent;

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const JokesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

interface SpinnerProps {
  showJokes: boolean;
}

const Spinner: FC<SpinnerProps> = ({ showJokes }) => {
  const [joke, setJoke] = useState("");
  const [displayedJokes, setDisplayedJokes] = useState<string[]>([]);
  const [initialFetchComplete, setInitialFetchComplete] = useState(false);

  useEffect(() => {
    let intervalId: any;

    const fetchRandomJoke = async () => {
      try {
        const { data, error } = await supabase.from("Jokes").select("joke");
        if (error) {
          console.error("Error fetching joke:", error.message);
        } else if (data) {
          const remainingJokes = data.filter(
            (joke) => !displayedJokes.includes(joke.joke)
          );
          if (remainingJokes.length === 0) {
            // All jokes have been displayed, reset the list
            setDisplayedJokes([]);
          }
          const randomIndex = Math.floor(Math.random() * remainingJokes.length);
          const randomJoke = remainingJokes[randomIndex]?.joke || "";
          setDisplayedJokes((prevJokes) => [...prevJokes, randomJoke]);
          setJoke(randomJoke);
        }
      } catch (error: any) {
        console.error("Error fetching joke:", error.message);
      }
    };

    if (showJokes) {
      fetchRandomJoke();
      intervalId = setInterval(fetchRandomJoke, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (joke !== "" && !initialFetchComplete) {
      setInitialFetchComplete(true);
    }
  }, [joke]);

  return (
    <Container>
      {showJokes ? (
        <>
          {initialFetchComplete && joke && (
            <JokesWrapper>
              <h4>Travel Trips by TRAILBLAZER:</h4>
              <p>{joke}</p>
            </JokesWrapper>
          )}
          <SpinningRingWrapper>
            <Ring />
            <Ring />
            <Ring />
            <Ring />
          </SpinningRingWrapper>
        </>
      ) : (
        <SpinningRingWrapper>
          <Ring />
          <Ring />
          <Ring />
          <Ring />
        </SpinningRingWrapper>
      )}
    </Container>
  );
};

export default Spinner;
