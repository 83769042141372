async function updateTrip(updatedTrip: any, tripId: number, supabase: any) {
    try {
      const response = await fetch(
        `https://smart-travel-backend.vercel.app/update-gpt`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTrip),
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data);
        console.log('Updating trip with id:', tripId);
        const { error } = await supabase.from("Trips").update([{
          location: updatedTrip.name,
          start_date: updatedTrip.startDate,
          end_date: updatedTrip.endDate,
          trip_details: data,
        }]).eq('id', tripId);
        if (error) {
          console.error('Supabase error:', error);
        }
        console.log('updated trip', tripId)
        return Promise.resolve();
      } else {
        throw new Error('Error updating trip');
      }
    } catch (error) {
      console.error("Error updating data:", error);
      return Promise.reject();
    }
  }

  export default updateTrip;