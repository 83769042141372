import React, { useState } from "react";
import * as S from "./AddFriendModalStyles";

interface ConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (email: string, tripId: string) => void;
  message: string;
  tripId: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
  message,
  tripId,
}) => {
  const [email, setEmail] = useState("");

  if (!isOpen) return null;

  return (
    <S.ModalOverlay>
      <S.ModalContainer>
        <S.ModalMessage>{message}</S.ModalMessage>
       <S.FriendEmailInput
        type="text"
        value={email}  // bind the input to the state variable
        onChange={(e) => setEmail(e.target.value)}  // update state on change
       />
        <S.ButtonsContainer>
          <S.CancelButton onClick={onCancel}>Cancel</S.CancelButton>
          <S.ConfirmButton onClick={() => onConfirm(email, tripId)}>Add Friend</S.ConfirmButton>
        </S.ButtonsContainer>
      </S.ModalContainer>
    </S.ModalOverlay>
  );
};

export default ConfirmationModal;