import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createClient, Session } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import Home from "./Home/Home";
import Profile from "./Profile/Profile";
import Trips from "./Trips/Trips";
import Nav from "./Nav";
import * as S from "./Styles";
import { GlobalStyle } from './Styles';

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_KEY as string
);

const signOut = async () => {
  await supabase.auth.signOut();
};

function NotFound() {
  return <S.PageNotFoundContainer>Page not found :(</S.PageNotFoundContainer>;
}

function App() {
  const [session, setSession] = useState<Session | null>(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <S.AuthContainer>
        <GlobalStyle />
        <S.LogoContainerAuth>
             <S.Logo src="./trail.png"/>
          </S.LogoContainerAuth>
        <S.AuthWrapper>
          <Auth
            providers={[]}
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
          />
        </S.AuthWrapper>
      </S.AuthContainer>
    );
  } else {
    return (
      <Router>
        <GlobalStyle />
          <S.LogoContainer>
             <S.Logo src="./trail.png"/>
          </S.LogoContainer>
          <S.SignOutButton onClick={() => signOut()}>Sign Out</S.SignOutButton>
          <Nav />
          <Routes>
            <Route path="/" element={<Home session={session} />} />
            <Route path="/profile" element={<Profile session={session} />} />
            <Route path="/trips" element={<Trips session={session} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
      </Router>
    );
  }
}

export default App;
